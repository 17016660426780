import { ErrorResponse, onError } from '@apollo/client/link/error';

import { useCurrentQuery } from '@/core/navigation/use-current-query';

export const useErrorLink = () => {
  const { getRouterNext } = useCurrentQuery();

  return onError((error: ErrorResponse) => {
    const { graphQLErrors, networkError } = error;

    if (!graphQLErrors) {
      return;
    }

    const [firstError] = graphQLErrors;

    switch (firstError?.extensions?.code) {
      case 'invalid-jwt':
        // Should renew the token
        location.href = `/logout${getRouterNext() ?? ''}`;
        break;
      default:
        break;
    }

    graphQLErrors.forEach(({ extensions, message }) => {
      // TODO: better handling. Maybe send to Sentry?
      console.error(`GQL Error message: ${message}`);
      console.error('GQL Error extensions.internal:', extensions?.internal);
    });

    if (networkError) {
      console.error(`[Network error]: ${JSON.stringify(networkError)}`);
    }
  });
};
