import {
  Avatar,
  Button,
  Container, IconButton, Panel, Separator, Stack,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useApplications } from '@/api/application';
import { useUser } from '@/api/user';
import { Navigation } from '@/components/common/mobile/navigation';
import { PullToRefresh } from '@/components/common/mobile/pull-to-refresh';
import { Weather } from '@/components/common/mobile/weather';
import { ApplicationModal } from '@/components/common/shared/application-modal';
import { Logo } from '@/components/common/shared/logo';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { useApplicationModalContext } from '@/context/use-application-modal-context';
import { useHistoryContext } from '@/context/use-hisotry-context';
import { useCurrentQuery } from '@/core/navigation/use-current-query';

import { CommonLayoutType } from '../../types';
import styles from './shell-layout.module.css';

export const ShellLayout: CommonLayoutType = ({
  children,
  className,
  ...otherProps
}) => {
  const router = useRouter();
  const { user } = useUser();
  const { appid } = useCurrentQuery();
  const { applications, currentApplication } = useApplications({ userId: user?.id });
  const { goBack, shouldShowBack } = useHistoryContext();
  const { openApplicationModal } = useApplicationModalContext();
  const hasMultipleApplications = useMemo(() => applications.length > 1, [applications]);

  return (
    <BaseLayout>
      <Stack
        as="main"
        className={clsx(styles.ShellLayout, className)}
        data-has-app-switch={hasMultipleApplications}
        {...otherProps}
      >
        <Panel as="header" vibrant className={styles.Header}>
          <Stack rowGap={8}>
            <Stack
              vAlign="center"
              direction="row"
              rowGap={24}
              hPadding={16}
              vPadding={shouldShowBack ? undefined : 4}
            >
              <Stack direction="row" vAlign="center" hAlign="start" fill={false}>
                {shouldShowBack
                  ? <IconButton onClick={goBack} icon="ctrl-left" kind="flat" />
                  : (
                    <Stack
                      as="a"
                      onClick={async () => router.push('/account')}
                      vAlign="center"
                      hAlign="center"
                    >
                      <Avatar src={user?.avatarUrl} dimension="small" />
                    </Stack>
                  )}
              </Stack>

              <Stack className={styles.HeaderCenter} direction="row" vAlign="center" hAlign="center" fill={false}>
                <Logo className={styles.Logo} width="48" variant="mark" />
              </Stack>
              <Stack direction="row" vAlign="center" hAlign="end" columnGap={16} fill={false}>
                <Weather applicationId={appid} />
              </Stack>
            </Stack>

            <Separator dimmed={1} />

            {hasMultipleApplications && (
              <Button
                className={styles.ApplicationButton}
                onClick={openApplicationModal}
                kind="flat"
                dimension="small"
              >
                {currentApplication?.name}
              </Button>
            )}
            <Separator dimmed={1} />
          </Stack>
        </Panel>

        <PullToRefresh>
          <Container
            className={styles.Container}
            data-scroller
          >
            {children}
          </Container>
        </PullToRefresh>

        <Stack
          hAlign="space-between"
          className={styles.Toolbar}
          vAlign="center"
        >
          <Separator dimmed={1} />
          <Navigation />
        </Stack>
      </Stack>

      <ApplicationModal
        applications={applications}
        currentApp={currentApplication}
      />
    </BaseLayout>
  );
};
