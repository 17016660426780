
import { Spinner } from '@lualtek/react-components';
import { useRouter } from 'next/router';
import {
  useEffect, useMemo, useState,
} from 'react';
import { match } from 'ts-pattern';

import { Meta } from '@/components/common/shared/meta';
import { getJWT } from '@/core/auth/jwt';
import { useTranslate } from '@/core/i18n';
import { useCurrentQuery } from '@/core/navigation/use-current-query';

import styles from './init-app.module.css';

export const InitPublicApp: FCChildren = (({ children }) => {
  const [loading, setLoading] = useState(true);
  const { next } = useCurrentQuery();
  const { lang } = useTranslate();
  const router = useRouter();
  const jwt = getJWT();

  // If has jwt and is not trying to logout or go to demo page, redirect to next
  const shouldRedirect = useMemo(
    () => match(router.pathname)
      .with('/logout', () => false)
      .with(`/${lang}/logout`, () => false)
      .when(path => path.includes('/demo/'), () => false)
      .otherwise(() => !!jwt),
    [jwt, router.pathname, lang],
  );

  useEffect(() => {
    if (shouldRedirect) {
      window.location.href = next;
      return;
    }

    setLoading(false);
  }, [next, shouldRedirect]);

  return (
    <>
      <Meta />
      {loading ? <div className={styles.LoadingWall}><Spinner /></div> : <>{children}</>}
    </>
  );
});
