import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { useMemo } from 'react';

import introspectionResult from '@/generated/fragments';

import { useAuthLink } from './links/auth-link';
import { useErrorLink } from './links/error-link';
import { httpLink } from './links/http-link';
import { useRefreshTokenLink } from './links/token-refresh-link';
import { wsLink } from './links/ws-link';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const isServer = () => typeof window === 'undefined';

const operationIsSubscription = (operation: Operation): boolean => {
  const definition = getMainDefinition(operation.query);
  return (
    definition.kind === 'OperationDefinition'
    && definition.operation === 'subscription'
  );
};

const getApolloLink = ({
  tokenRefreshLink,
  errorLink,
  authLink,
}: {
  tokenRefreshLink: TokenRefreshLink;
  errorLink: ApolloLink;
  authLink: ApolloLink;
}) => {
  if (isServer()) {
    return ApolloLink.from([authLink, httpLink]);
  }

  const link = ApolloLink.from(wsLink
    ? [
      tokenRefreshLink,
      authLink,
      ApolloLink.split(operationIsSubscription, wsLink),
      errorLink,
      httpLink,
    ] : [
      tokenRefreshLink,
      authLink,
      errorLink,
      httpLink,
    ]);

  return link;
};

const createApolloClient = (link: ApolloLink) => new ApolloClient({
  link,
  ssrMode: isServer(),
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          // TODO: we should find a way to fix these types
          notifications: {
            // https://www.apollographql.com/docs/react/pagination/core-api/
            // https://www.apollographql.com/docs/react/pagination/key-args/#setting-keyargs
            keyArgs: ['where'],
            // @ts-expect-error No types
            merge(existing, incoming, { args: { offset = 0 } }) {
            // Slicing is necessary because the existing data is
            // immutable, and frozen in development.
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const merged = existing ? existing.slice(0) : [];
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < incoming.length; ++i) {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands
                merged[offset + i] = incoming[i];
              }

              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return merged;
            },
          },
          hygraph: {
            merge: (_, incoming: unknown) => incoming, // Prevent merging
            keyArgs: false,
          },
          // slice the existing or incoming data based on the limit argument
          // we need to slice if args.limit exist and we need to slice the incoming or existing .chart.dataset array
          device_detail: {
            // Slice the existing or incoming data based on the limit argument
            merge(existing: any, _: any, args: { args: Record<string, any> | null }) {
              if (existing) {
                // If the existing data has a .chart.dataset array and args.limit exists, slice it
                if (existing.chart?.dataset && args?.args?.limit) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                  return {
                    ...existing,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    chart: {
                      ...existing.chart,
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      dataset: existing.chart.dataset.slice(0, args?.args?.limit),
                    },
                  };
                }
              }

              // If no existing data or no slicing needed, return the existing data as-is
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return existing;
            },
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.NODE_ENV !== 'production',
  assumeImmutableResults: true,
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network' },
  },
  credentials: 'include',
});

export const initializeApollo = (link: ApolloLink, initialState = {}) => {
  const innerApolloClient: ApolloClient<NormalizedCacheObject> = apolloClient ?? createApolloClient(link);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = innerApolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    innerApolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return innerApolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = innerApolloClient;

  return innerApolloClient;
};

export const useApollo = (initialState: Record<string, unknown>) => {
  const authLink = useAuthLink();
  const errorLink = useErrorLink();
  const tokenRefreshLink = useRefreshTokenLink();
  const apolloLink = getApolloLink({
    errorLink,
    tokenRefreshLink,
    authLink,
  });

  const store = useMemo(() => initializeApollo(apolloLink, initialState), [apolloLink, initialState]);
  return store;
};
