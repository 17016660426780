import {
  Button, Drawer, DrawerProps, IconButton, List, Separator, Stack, Title, useOverlayContext,
} from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';

import { useNotificationReleaseContent } from '@/api/notifications';
import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';

import styles from './drawers.module.css';

export const ReleaseDrawer: FC<DrawerProps> = ({ isOpen, onClose, ...otherProps }) => {
  const router = useRouter();
  const { t } = useTranslate();
  const { titleId } = useOverlayContext();
  const { isMobile } = useMobileContext();
  const { releaseContent } = useNotificationReleaseContent({
    notificationContentId: Number(router.query.notificationContentId),
  });

  const hasNew = useMemo(
    () => releaseContent?.hygraph?.release.new && releaseContent?.hygraph?.release.new.length > 0,
    [releaseContent],
  );
  const hasChanged = useMemo(
    () => releaseContent?.hygraph?.release.changed && releaseContent?.hygraph?.release.changed.length > 0,
    [releaseContent],
  );

  return (
    <Drawer isOpen={isOpen} onClose={onClose} showHeader={false} maxWidth="630px" {...otherProps}>
      <Stack hAlign="center" vAlign="center" vPadding={isMobile ? 96 : 136} className={styles.ReleaseHeader}>
        <div className={styles.Ripples}>
          {Array.from(Array(8).keys()).map(i => (
            <div
              key={i}
              className={styles.Ripple}
              style={{
                '--scale': (i + 0.5) / 2,
              }}
            />
          ))}
        </div>

        <Title
          as="span"
          className={styles.VersionNumber}
          data-theme="dark"
          level="1"
          lineHeight="small"
          id={titleId}
        >
          v
          {releaseContent?.hygraph?.release?.version}
        </Title>
        <IconButton
          icon="remove"
          kind="secondary"
          data-theme="dark"
          onClick={onClose}
          title={t('common:glossary.close')}
          className={styles.FloatingClose}
        />
      </Stack>
      <Stack hPadding={isMobile ? 24 : 40} vPadding={40} rowGap={32}>
        {hasNew && (
          <>
            <Title level="4">{t('common:notifications.releasesDrawer.newTitle')}</Title>
            <List gap={16} dimension="small">
              {releaseContent?.hygraph?.release.new.map(change => (
                <List.Li key={change} marker="plus" markerColor="var(--highlight-green-foreground)">
                  {change}
                </List.Li>
              ))}
            </List>
          </>
        )}

        {(hasChanged && hasNew) && <Separator />}

        {hasChanged && (
          <>
            <Title level="4">{t('common:notifications.releasesDrawer.changedTitle')}</Title>
            <List gap={16} dimension="small">
              {releaseContent?.hygraph?.release.changed.map(change => (
                <List.Li key={change} marker="refresh" markerColor="var(--highlight-cyan-foreground)">
                  {change}
                </List.Li>
              ))}
            </List>
          </>
        )}

        <Stack hAlign="start">
          <Button onClick={onClose} dimension="big">{t('common:glossary.close')}</Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
