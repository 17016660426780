import { TokenRefreshLink } from 'apollo-link-token-refresh';

import { getJWTPayload, setJWT } from '@/core/auth/jwt';
import { useTranslate } from '@/core/i18n';
import { useCurrentQuery } from '@/core/navigation/use-current-query';

// TODO: check this better
export const UNAUTH_ROUTES = [
  '/login',
  '/logout',
  '/get',
  '/en/login',
  '/en/logout',
  '/en/get',
  '/demo/',
];

const avoidRefreshToken = () => UNAUTH_ROUTES.some(route => window.location.pathname.includes(route));

export const useRefreshTokenLink = () => {
  const { getRouterNext } = useCurrentQuery();
  const { lang } = useTranslate();
  return new TokenRefreshLink({
    accessTokenField: 'jwt',
    // Indicates the current state of access token expiration
    // If token not yet expired or user doesn't have a token (guest) true should be returned
    isTokenValidOrUndefined: async () => Number(getJWTPayload()?.exp) > Date.now() / 1000 || avoidRefreshToken(),
    fetchAccessToken: async () => fetch('/api/auth/refresh_token', {
      method: 'POST',
      credentials: 'include',
      headers: { 'lualtek-app-host': window.location.host },
    }),
    handleFetch: (accessToken: string) => setJWT(accessToken),
    handleError: () => {
      window.location.href = lang === 'it' ? `/logout${getRouterNext() ?? ''}` : `/${lang}/logout${getRouterNext() ?? ''}`;
    },
  });
};
