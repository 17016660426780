import { RichText, RichTextProps } from '@graphcms/rich-text-react-renderer';
import {
  List, ListItemProps, ListProps, Text, TextProps, Title, TitleProps,
} from '@lualtek/react-components';
import { LinkProps } from 'next/link';
import { Except } from 'type-fest';

import { Link } from '@/core/i18n';

export type CmsContentProps = {
  content?: RichTextProps['content'] | null;
  aProps?: Except<LinkProps, 'href'>;
  textProps?: TextProps;
  boldProps?: TextProps;
  italicProps?: TextProps;
  listProps?: ListProps;
  listItemProps?: ListItemProps;
  h1Props?: TitleProps;
  h2Props?: TitleProps;
  h3Props?: TitleProps;
  h4Props?: TitleProps;
  h5Props?: TitleProps;
  h6Props?: TitleProps;
}

export const CmsContent = ({
  content,
  aProps,
  textProps,
  boldProps,
  italicProps,
  listProps,
  listItemProps,
  h1Props,
  h2Props,
  h3Props,
  h4Props,
  h5Props,
  h6Props,
}: CmsContentProps) => (content ? (
  <RichText
    content={content}
    renderers={{
      a: ({ href, children }) => <Link href={href!} {...aProps}>{children}</Link>,
      p: ({ children }) => <Text {...textProps}>{children}</Text>,
      bold: ({ children }) => <Text as="b" weight="bold" {...boldProps}>{children}</Text>,
      italic: ({ children }) => <Text as="em" {...italicProps}>{children}</Text>,
      ul: ({ children }) => <List {...listProps}>{children}</List>,
      ol: ({ children }) => <List as="ol" {...listProps}>{children}</List>,
      li: ({ children }) => <List.Li {...listItemProps}><Text>{children}</Text></List.Li>,
      h1: ({ children }) => <Title as="h1" level="1" {...h1Props}>{children}</Title>,
      h2: ({ children }) => <Title as="h2" level="2" {...h2Props}>{children}</Title>,
      h3: ({ children }) => <Title as="h3" level="3" {...h3Props}>{children}</Title>,
      h4: ({ children }) => <Title as="h4" level="4" {...h4Props}>{children}</Title>,
      h5: ({ children }) => <Title as="h5" level="5" {...h5Props}>{children}</Title>,
      h6: ({ children }) => <Title as="h6" level="6" {...h6Props}>{children}</Title>,
    }}
  />
) : null);
