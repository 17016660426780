import { Stack, Text } from '@lualtek/react-components';

import styles from './weather.module.css';

type WeatherRowProps = {
  icon: string;
  day: string;
  tempMax: number;
  tempMin: number;
}

export const WeatherRow: FCClass<WeatherRowProps> = ({
  icon,
  tempMax,
  tempMin,
  day,
}) => (
  <Stack
    direction="row"
    vAlign="center"
    hAlign="start"
    vPadding={8}
    hPadding={16}
  >
    <img
      className={styles.IconForecast}
      alt=""
      src={icon}
      width={56}
      height={56}
    />
    <Stack hPadding={16}>
      <Text as="span" lineHeight="small" weight="bold" size={22}>{day}</Text>
      <Text as="span" lineHeight="small" dimmed={5} size={18}>
        {tempMax}
        {' '}
        /
        {' '}
        {tempMin}
        {' '}
        °C
      </Text>
    </Stack>
  </Stack>
);
