import { Button } from '@lualtek/react-components';
import { useRouter } from 'next/router';

import { locales, useTranslate } from '@/core/i18n';

export const LangSwitcher = () => {
  const { lang } = useTranslate();
  const router = useRouter();
  return locales.map(lng => lng !== lang && (
    <Button
      href={`${lng === 'it' ? '' : `/${lng}`}${router.asPath}`}
      as="a"
      key={lng}
      kind="flat"
    >
      {lng.toUpperCase()}
    </Button>
  ));
};
