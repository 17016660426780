import jsonwebtoken from 'jsonwebtoken';

type JWTPayload = {
  'https://hasura.io/jwt/claims': {
    'x-hasura-default-role': string;
    'x-hasura-allowed-roles': string[];
    'x-hasura-user-id': string;
  };
  exp: number;
}

type JWTPayloadDecoded = {
  userId: number;
  role: string;
  exp: number;
}

// Cache JWT result got from session
let JWT = '';
let JWTPayload: JWTPayloadDecoded | null = null;

export const getJWT = () => {
  // If not browser
  if (typeof window === 'undefined') {
    return JWT;
  }

  if (JWT) {
    return JWT;
  }

  return localStorage.getItem('jwt') ?? sessionStorage.getItem('jwt') ?? '';
};

export const setJWT = (jwt: string) => {
  sessionStorage.setItem('jwt', jwt);
  localStorage.setItem('jwt', jwt);
  JWT = jwt;
};

export const getJWTPayload = (): JWTPayloadDecoded | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const jwt = getJWT();
  if (!jwt) {
    return null;
  }

  const payload = jsonwebtoken.decode(jwt) as JWTPayload;
  if (!payload) {
    return null;
  }

  JWTPayload = {
    userId: Number(payload['https://hasura.io/jwt/claims']['x-hasura-user-id']),
    role: payload['https://hasura.io/jwt/claims']['x-hasura-default-role'],
    exp: payload.exp,
  };

  return JWTPayload;
};
