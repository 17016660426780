import { useEffect } from 'react';

import { useFeedbackContext } from '@/context/use-feedback-context';

import { useTranslate } from '../i18n';

export const useIsOffline = () => {
  const { addFeedback } = useFeedbackContext();
  const { t } = useTranslate();

  useEffect(() => {
    const handleOffline = () => {
      addFeedback({
        title: t('common:feedback.offline.title'),
        children: t('common:feedback.offline.text'),
        kind: 'warning',
      });
    };

    const handleOnline = () => {
      addFeedback({
        title: t('common:feedback.online.title'),
        children: t('common:feedback.online.text'),
      });
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, [addFeedback, t]);
};
