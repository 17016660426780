import {
  BlankButton,
  Icon, Modal, Skeleton, Stack, Text, Title,
} from '@lualtek/react-components';
import { Fragment, useState } from 'react';

import { useApplicationWeather } from '@/api/application/application';
import { useFeedbackContext } from '@/context/use-feedback-context';
import { getWindDirection } from '@/core/helpers/get-wind-direction';
import { useI18nHelpers, useTranslate } from '@/core/i18n';
import { useDateTime } from '@/services/datetime/use-datetime';
import { useWeatherHelpers } from '@/services/weather/use-weather-helpers';

import styles from './weather.module.css';
import { WeatherRow } from './weather-row';

export type WeatherProps = PropsWithClass<{
  applicationId: number;
}>

export const Weather = ({
  applicationId,
}: WeatherProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tError, tGetCardinalDirection } = useI18nHelpers();
  const { t } = useTranslate();
  const { addFeedback } = useFeedbackContext();
  const { weather, loading, error } = useApplicationWeather({ applicationId }, {
    onError: (error) => {
      addFeedback({
        title: t('common:feedback.error.weather.title'),
        children: error?.key ? tError(error?.key) : t('common:feedback.error.weather.text'),
        kind: 'warning',
      });
    },
  });
  const { iconWeatherForId } = useWeatherHelpers();
  const { dayMonthDescriptive } = useDateTime();

  return !loading && !error ? (
    <>
      <Stack
        as={BlankButton}
        onClick={() => setIsModalOpen(true)}
        direction="row"
        hAlign="start"
        vAlign="center"
        fill={false}
      >
        <Text size={14} weight="bold">
          {weather?.current.temperature}
          {' '}
          °C
        </Text>
        <img
          className={styles.Icon}
          alt=""
          src={`/weather/${iconWeatherForId(weather?.current.weatherIconId)}.svg`}
          width={96}
          height={96}
        />
      </Stack>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Modal.Content title={t('common:glossary.forecast')} headerTint="var(--dimmed-1)" scrollInside>
          <Stack
            className={styles.Weather}
            hAlign="center"
            vAlign="start"
            fill={false}
          >
            <img
              alt=""
              src={`/weather/${iconWeatherForId(weather?.current.weatherIconId)}.svg`}
              width={96}
              height={96}
            />
            <Stack hAlign="center">
              <Title level="3">
                {weather?.current.temperature}
                {' '}
                °C
              </Title>

              {weather?.current.time && <Text>{dayMonthDescriptive(weather.current.time)}</Text>}

              <Stack direction="row" columnGap={16} hAlign="center" fill={false}>
                {(weather?.current.humidity) && (
                  <Stack direction="row" vAlign="center" columnGap={8}>
                    <Icon source="humidity" />
                    <Text size={16}>
                      {weather.current.humidity}
                      %
                    </Text>
                  </Stack>
                )}
                {(weather?.current.windSpeed) && (
                  <Stack direction="row" vAlign="center" columnGap={8}>
                    <Icon source="wind" />
                    <Text size={16}>
                      {weather.current.windSpeed}
                      {' '}
                      m/s
                    </Text>
                  </Stack>
                )}
                {(weather?.current.windDegree !== undefined) && (
                  <Stack direction="row" vAlign="center" columnGap={8}>
                    <Icon source="arrow-up" style={{ transform: `rotate(${weather.current.windDegree}deg)` }} />
                    <Text size={14}>
                      {tGetCardinalDirection(getWindDirection(weather.current.windDegree))}
                    </Text>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack vPadding={32} className={styles.Forecast}>
            {weather?.forecast.map(day => (
              <Fragment key={String(day.time)}>
                <WeatherRow
                  icon={`/weather/${iconWeatherForId(day.weatherIconId)}.svg`}
                  tempMax={day.temperature.max}
                  tempMin={day.temperature.min}
                  day={dayMonthDescriptive(day.time)}
                />
              </Fragment>
            ))}
          </Stack>
        </Modal.Content>
      </Modal>
    </>
  ) : <Skeleton height="24px" width="24px" />;
};
