/**
 * Context where to store apollo calls to be refetched when user pull to refresh
 */

import constate from 'constate';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

type RefetchFunction = () => Promise<unknown>;

const usePullToRefresh = () => {
  const router = useRouter();
  const [refetchFunctions, setRefetchFunctions] = useState<Set<RefetchFunction>>(new Set());

  const addRefetchFunctions = useCallback((refetchFns: RefetchFunction[]) => {
    setRefetchFunctions((prevRefetchFunctions) => {
      const newRefetchFunctions = new Set(prevRefetchFunctions);
      refetchFns.forEach(refetchFn => newRefetchFunctions.add(refetchFn));
      return newRefetchFunctions;
    });
  }, []);

  const removeRefetchFunction = useCallback((refetchFn: RefetchFunction) => {
    setRefetchFunctions((prevRefetchFunctions) => {
      const newRefetchFunctions = new Set(prevRefetchFunctions);
      newRefetchFunctions.delete(refetchFn);
      return newRefetchFunctions;
    });
  }, []);

  const refetchAll = useCallback(async () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const refetchFn of refetchFunctions) {
      await refetchFn();
    }
  }, [refetchFunctions]);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setRefetchFunctions(new Set()));
  }, [router.events]);

  return {
    addRefetchFunctions,
    removeRefetchFunction,
    refetchAll,
  };
};

export const [
  PullToRefreshContextProvider,
  usePullToRefreshContext,
] = constate(usePullToRefresh);
