import {
  BlankButton,
  ClampText,
  Icon, Stack, Text,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { MouseEventHandler, useCallback, useRef } from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';

import styles from './apps-list.module.css';

type AppListItemProps = {
  id: number;
  name: string;
  selectedApplication?: boolean;
  devices?: number;
  automations?: number;
  isOnly?: boolean;
  onAppSelect?: (id: number) => void;
}

export const AppListItem: FCClass<AppListItemProps> = ({
  className,
  id,
  name,
  devices,
  automations,
  selectedApplication,
  onAppSelect,
  isOnly,
  ...otherProps
}) => {
  const itemRef = useRef<HTMLButtonElement>(null);
  const [tabIndex, isFocused, handleKeyDown, handleClick] = useRovingTabIndex(itemRef, false);

  useFocusEffect(isFocused, itemRef);

  const triggerClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      if (!isOnly) {
        handleClick();
        onAppSelect?.(id);
      }
    },
    [handleClick, id, isOnly, onAppSelect],
  );

  return (
    <Stack
      as={BlankButton}
      className={clsx(styles.AppListItem, className)}
      direction="row"
      fill={false}
      hAlign="space-between"
      vAlign="center"
      vPadding={16}
      hPadding={16}
      columnGap={16}
      data-app-list-item-is-only={isOnly}
      onClick={triggerClick}
      ref={itemRef}
      disabled={isOnly}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      {...otherProps}
    >
      <Stack>
        <Text as="span" size={18} weight="bold">
          <ClampText rows={1}>{name}</ClampText>
        </Text>
        <Stack direction="row" columnGap={16} className={styles.Meta}>

          <Stack as={Text} size={14} direction="row" columnGap={4} vAlign="center" inline>
            <Icon source="device" dimension={12} />
            {devices}
          </Stack>

          <Stack as={Text} size={14} direction="row" columnGap={4} vAlign="center" inline>
            <Icon source="decentralize" dimension={12} />
            {automations}
          </Stack>

          {/* <Stack as={Text} size={14} direction="row" columnGap={4} vAlign="center" inline>
            <Icon source="bell" dimension={12} />
            {alarms}
          </Stack> */}
        </Stack>
      </Stack>
      <Stack direction="row" vAlign="center" inline hPadding={8} columnGap={16}>
        {selectedApplication && <Icon fill="var(--highlight-green-foreground)" source="check" />}
      </Stack>
    </Stack>
  );
};
