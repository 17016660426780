import constate from 'constate';
import { useRouter } from 'next/router';
import {
  useCallback, useState,
} from 'react';
import { useLocalstorageState } from 'rooks';

import { STORAGE_KEY_LOCAL_APP } from '@/services/local-storage/keys';

const useApplicationModal = () => {
  const router = useRouter();
  const [isApplicationModalVisible, setIsApplicationModalVisible] = useState(false);
  const [, setLocalApp] = useLocalstorageState(STORAGE_KEY_LOCAL_APP);

  const openApplicationModal = useCallback(() => {
    setIsApplicationModalVisible(true);
  }, []);

  const closeApplicationModal = useCallback(() => {
    setIsApplicationModalVisible(false);
  }, []);

  const goToApplication = useCallback((appId: number) => {
    setLocalApp(appId);
    void router.replace(`/app/${appId}/dashboard`);
    closeApplicationModal();
  }, [setLocalApp, router, closeApplicationModal]);

  return {
    isApplicationModalVisible,
    openApplicationModal,
    closeApplicationModal,
    goToApplication,
  };
};

export const [
  ApplicationModalContextProvider,
  useApplicationModalContext,
] = constate(useApplicationModal);
