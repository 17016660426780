import Head from 'next/head';
import React, {
  PropsWithChildren, useMemo,
} from 'react';

export type MetaProps = PropsWithChildren<PropsWithClass> & {
  description?: string;
  title?: string;
  url?: string;
  canonical?: string;
  image?: string;
  siteName?: string;
  keywords?: string[];
}

export const MetaData = ({
  description = 'Controlla la tua coltivazione con LoRaWAN',
  title,
  siteName = 'Lualtek',
  image = `${process.env.NEXT_PUBLIC_DOMAIN}/cover.jpg`,
  keywords,
  url = process.env.NEXT_PUBLIC_DOMAIN,
  canonical,
  children,
}: MetaProps) => {
  const finalTitle = useMemo(() => (title ? `${title} – ${siteName}` : siteName), [siteName, title]);

  return (
    <Head>
      <title key="title">{finalTitle}</title>
      {keywords && <meta key="keywords" name="keywords" content={keywords.join()} />}

      {/* PWA Meta */}
      {canonical && <link key="canonical" rel="canonical" href={canonical} /> }
      <meta key="ogsitename" property="og:site_name" content={finalTitle} />
      <meta key="ogtitle" name="og:title" property="og:title" content={finalTitle} />
      <meta key="twittertitle" name="twitter:title" content={finalTitle} />
      <meta key="twittersite" property="twitter:site" content={siteName} />

      <meta key="description" name="description" content={description} />
      <meta key="twitterdescription" name="twitter:description" content={description} />
      <meta key="ogdescription" name="og:description" property="og:description" content={description} />

      <meta key="ogimage" property="og:image" content={image} />
      <meta key="twitterimage" name="twitter:image" content={image} />
      <meta key="ogimagealt" property="og:image:alt" content={`Page image for ${finalTitle}`} />
      <meta key="twitterimagealt" name="twitter:image:alt" content={`Page image for ${finalTitle}`} />

      {url && (
        <>
          <meta key="ogurl" property="og:url" content={url} />
          <meta key="twitterurl" property="twitter:url" content={url} />
        </>
      )}
      {children}
    </Head>
  );
};
