import {
  Badge,
  Button,
  IconButton, Panel, Popover, Stack, Text, Tooltip,
} from '@lualtek/react-components';

import { useNotificationsNotReadCount } from '@/api/notifications';
import { NotificationsTab } from '@/components/common/shared/notifications-tab';
import { Link, useTranslate } from '@/core/i18n';

import { useNotificationsLogic } from '../../shared/notification/use-notifications-logic';
import styles from './notifications.module.css';

type NotificationsProps = Record<string, unknown>

export const Notifications: FCClass<NotificationsProps> = ({
  className,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const { notReadCount } = useNotificationsNotReadCount();
  const {
    onCloseDrawer,
    renderDrawer,
    drawerOpened,
    onMarkAllRead,
  } = useNotificationsLogic();

  return (
    <>
      <Popover {...otherProps}>
        <Popover.Trigger>
          <Badge showBadge={notReadCount > 0}>
            <IconButton
              aria-label="Enable or disable notifications"
              icon="bell"
              kind="secondary"
            />
          </Badge>
        </Popover.Trigger>
        <Popover.Content style={{ zIndex: 1 }} align="end" alignOffset={-60} side="right">
          <Panel showGlow radius={24} vibrant vibrancyColor="background" bordered>
            <Stack
              direction="row"
              fill={false}
              hAlign="space-between"
              vAlign="center"
              hPadding={16}
              vPadding={8}
              columnGap={16}
            >
              <Text size={22} weight="bold">{t('common:glossary.notifications')}</Text>
              {notReadCount > 0 ? (
                <Button
                  dimension="small"
                  kind="flat"
                  sentiment="positive"
                  onClick={onMarkAllRead}
                >
                  {t('common:cta.markAllAsRead')}
                </Button>
              ) : null}
            </Stack>
            {/* <Separator /> */}
            <div className={styles.TabWrapper}>
              <NotificationsTab
                maxHeight="min(33vh, 500px)"
                minHeight="200px"
                listGap={4}
              />
              <Tooltip
                side="right"
                sideOffset={16}
                trigger={(
                  <IconButton
                    as={Link}
                    href="/account"
                    className={styles.Settings}
                    icon="settings-gear"
                    kind="flat"
                  />
                )}
              >
                {t('common:cta.notificationsSettings')}
              </Tooltip>
            </div>
          </Panel>
        </Popover.Content>
      </Popover>
      {renderDrawer({ isOpen: Boolean(drawerOpened), onClose: onCloseDrawer })}
    </>

  );
};
