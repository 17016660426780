import { ApolloError } from '@apollo/client';

export type APIHandledErrorExtension = {
  key: string;
  message: string;
  name: string;
  httpCode: number;
  details?: Record<string, unknown>;
}

export type APIHandleError = {
  message: string;
  extensions: APIHandledErrorExtension;
}

export class ApiHandlerErrorInstance extends Error {
  message: string;
  extensions: APIHandledErrorExtension;

  constructor(error: APIHandleError) {
    super(error.message);
    this.message = error.message;
    this.extensions = error.extensions;
  }
}

export const extractError = (error: ApolloError): APIHandledErrorExtension => {
  const [firstError] = error.graphQLErrors;

  return firstError?.extensions?.key ? firstError.extensions as APIHandledErrorExtension : {
    key: 'unknown_error',
    message: 'Unknown error, please contact us.',
    name: 'UnknownError',
    httpCode: 500,
  };
};
