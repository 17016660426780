import constate from 'constate';
import { ReactNode, useState } from 'react';

export type CrumbDetailProps = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  father?: {
    title: string;
    subtitle?: string;
  };
}

const useCrumbDetail = () => {
  const [crumbDetail, setCrumbDetail] = useState<CrumbDetailProps | null>();

  return {
    crumbDetail,
    setCrumbDetail,
  };
};

export const [
  CrumbDetailContextProvider,
  useCrumbDetailContext,
] = constate(useCrumbDetail);
