import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { ConsoleRoutes } from '@/core/navigation/use-navigation';

const generatePathParts = (path: string) => {
  // Remove any query parameters, as those aren't included in breadcrumbs
  const pathWithoutQuery = path.split('?')[0];
  // Break down the path between "/"s, removing empty entities
  // Ex:"/my/nested/path" --> ["my", "nested", "path"]
  // Remove also fragment if present
  const pathWithoutQueryAndFragment = pathWithoutQuery.split('#')[0];

  return pathWithoutQueryAndFragment.split('/')
    .filter(v => v.length > 0);
};

export const useBreadcrumbs = () => {
  const router = useRouter();

  const breads = useMemo(() => {
    const asPathNestedRoutes = generatePathParts(router.asPath);

    const crumblist = asPathNestedRoutes.map((subpath, idx) => {
      // We can get the partial nested route for the crumb
      // by joining together the path parts up to this point.
      const href = `/${asPathNestedRoutes.slice(0, idx + 1).join('/')}`;
      // The title will just be the route string for now
      const text = subpath as keyof typeof ConsoleRoutes;
      return { href, i18nKey: ConsoleRoutes[text]?.i18nKey };
    });

    // Remove useless part for breadcrumb including the app pre-route
    const cleanbreads = router.pathname.includes('app/[appid]') ? crumblist.slice(2) : crumblist;

    return cleanbreads;
  }, [router.asPath, router.pathname]);

  return {
    breads,
  };
};
