/**
 * Context where to store user/application/organization infos to be available in the whole app
 */

import constate from 'constate';
import { useState } from 'react';

export type InfoProps = {
  currentOrganizationId?: number;
}

const useInfo = () => {
  const [info, setInfo] = useState<InfoProps | null>();

  return {
    info,
    setInfo,
  };
};

export const [
  InfoContextProvider,
  useInfoContext,
] = constate(useInfo);
