import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { match } from 'ts-pattern';

import { useNotificationsMutations } from '@/api/notifications';
import { useTranslate } from '@/core/i18n';
import { useNavigationHelpers } from '@/core/navigation/use-navigation';
import {
  NotificationContentMessageDataFragment,
  NotificationContentReleaseDataFragment,
  NotificationDataFragment,
  NotificationKindsEnum,
} from '@/generated/graphql';

import { NotificationProps } from './notification';

export const DRAWER_KINDS = {
  EVENTS: 'events',
  RELEASE: 'releases',
  MESSAGE: 'messages',
};

const sentimentByEventKind: Record<NotificationKindsEnum, 'warning' | 'danger' | 'positive' | undefined> = {
  [NotificationKindsEnum.EVENT_DEVICE_MISSING]: 'warning',
  [NotificationKindsEnum.EVENT_SCENE_FAILED]: 'danger',
  [NotificationKindsEnum.EVENT_SCENE_SUCCESS]: 'positive',
  [NotificationKindsEnum.EVENT_SCENE_WARNING]: 'warning',
  [NotificationKindsEnum.DEVICE_EVENT]: undefined,
  [NotificationKindsEnum.EVENT_MESSAGE]: undefined,
  [NotificationKindsEnum.EVENT_RELEASE]: undefined,
};

type AllNotificationProps = NotificationDataFragment
| (NotificationDataFragment & (NotificationContentMessageDataFragment | NotificationContentReleaseDataFragment))

type NotificationPropsOthers = NotificationDataFragment
& (NotificationContentMessageDataFragment | NotificationContentReleaseDataFragment)

export const useNotificationRender = () => {
  const { dynamicGlossary } = useTranslate();
  const router = useRouter();
  const { pathTo } = useNavigationHelpers();
  const { markRead } = useNotificationsMutations();

  const onClickEvent = useCallback((notification: NotificationDataFragment) => {
    match(notification.kind)
      .with(NotificationKindsEnum.EVENT_DEVICE_MISSING, () => {
        void router.push(pathTo({
          kind:
          'device-detail',
          entityId: notification.content.values!.deviceId,
        }));
      })
      .with(
        NotificationKindsEnum.EVENT_SCENE_FAILED,
        NotificationKindsEnum.EVENT_SCENE_SUCCESS,
        NotificationKindsEnum.EVENT_SCENE_WARNING,
        () => {
          void router.push(pathTo({ kind: 'automation-detail', entityId: notification.content.values!.automationId }));
        },
      )
      .run();
  }, [pathTo, router]);

  const onClickOther = useCallback(
    (notification: NotificationPropsOthers) => {
      void router.push({
        query: {
          ...router.query,
          drawer: notification.kind === NotificationKindsEnum.EVENT_MESSAGE
            ? DRAWER_KINDS.MESSAGE
            : DRAWER_KINDS.RELEASE,
          notificationContentId: notification.content.id,
        },
      }, undefined, { shallow: true });
    },
    [router],
  );

  const generateCommonProps = useCallback(
    (notification: AllNotificationProps): Partial<NotificationProps> => ({
      isNew: !notification.read,
      kind: match(notification.kind)
        .with(
          NotificationKindsEnum.EVENT_DEVICE_MISSING,
          NotificationKindsEnum.EVENT_SCENE_FAILED,
          NotificationKindsEnum.EVENT_SCENE_SUCCESS,
          NotificationKindsEnum.EVENT_SCENE_WARNING,
          () => 'event' as const,
        )
        .with(NotificationKindsEnum.EVENT_MESSAGE, () => 'message' as const)
        .with(NotificationKindsEnum.EVENT_RELEASE, () => 'release' as const)
        .run(),
      sentiment: sentimentByEventKind[notification.kind],
      date: notification.createdAt,
      appName: notification.content.values?.applicationName,
    }), [],
  );

  const generateEventProps = useCallback(
    (notification: NotificationPropsOthers): NotificationProps => ({
      ...generateCommonProps(notification),
      title: dynamicGlossary(notification.kind).contents(notification.content.values!),
      onClick: () => {
        onClickEvent(notification);
        void markRead({ ids: [notification.id] }, { shouldRefetch: true });
      },
    }),
    [dynamicGlossary, onClickEvent, generateCommonProps, markRead],
  );

  const generateOthersProps = useCallback(
    (notification: NotificationPropsOthers): NotificationProps => ({
      ...generateCommonProps(notification),
      title: '',
      onClick: () => {
        onClickOther(notification);
        void markRead({ ids: [notification.id] }, { shouldRefetch: true });
      },
    }),
    [generateCommonProps, onClickOther, markRead],
  );

  return {
    generateEventProps,
    generateOthersProps,
    markRead,
  };
};
