import { ToastProps } from '@lualtek/react-components';
import constate from 'constate';
import {
  useCallback, useState,
} from 'react';

import { useTranslate } from '@/core/i18n';

type FeedbackProps = ToastProps

let latestFeedbackId = 0;

const useFeedback = () => {
  const { t } = useTranslate();
  const [feedbacks, setFeedbacks] = useState<FeedbackProps[]>([]);

  const addFeedback = useCallback((feedback: FeedbackProps) => {
    latestFeedbackId += 1;
    setFeedbacks(oldFeedbacks => [...oldFeedbacks, {
      id: String(latestFeedbackId),
      ...feedback,
    }]);
  }, []);

  const removeFeedback = useCallback((id: string) => {
    setFeedbacks(oldFeedbacks => oldFeedbacks.filter(feedback => feedback.id !== id));
  }, []);

  const resetFeedbacks = useCallback(() => {
    setFeedbacks([]);
  }, []);

  const addLoadingErrorFeedback = useCallback(() => {
    addFeedback({
      title: t('common:feedback.error.loadData.title'),
      children: t('common:feedback.error.loadData.text'),
      kind: 'danger',
    });
  }, [addFeedback, t]);

  return {
    feedbacks,
    addFeedback,
    addLoadingErrorFeedback,
    resetFeedbacks,
    removeFeedback,
  };
};

export const [
  FeedbackContextProvider,
  useFeedbackContext,
] = constate(useFeedback);
