import constate from 'constate';
import { useRouter } from 'next/router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { ConsoleRoutes } from '@/core/navigation/use-navigation';

const BACK_MAP_PATHNAME = {
  '/app/[appid]/areas/[areaid]/[deviceid]': ConsoleRoutes.areas.href,
  '/app/[appid]/analysis/[analysisid]': ConsoleRoutes.analysis.href,
  '/app/[appid]/automations/[automationid]': ConsoleRoutes.automations.href,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/account': () => '/',
};

const useHistory = () => {
  const [history, setHistory] = useState<{
    previous: string | null;
    current: string | null;
    currentPathname: string | null;
  }>({
    previous: null,
    current: null,
    currentPathname: null,
  });

  const router = useRouter();

  useEffect(() => {
    setHistory(history => ({
      previous: history.current,
      current: router.asPath,
      currentPathname: router.pathname,
    }));
  }, [router.asPath, router.pathname, setHistory]);

  const shouldShowBack = useMemo(
    () => !Object.values(ConsoleRoutes).some(route => route.pathname === history.currentPathname),
    [history.currentPathname],
  );

  const goBack = useCallback(async () => {
    if (history.previous) {
      router.back();
      return;
    }

    if (history.currentPathname && BACK_MAP_PATHNAME[history.currentPathname as keyof typeof BACK_MAP_PATHNAME]) {
      await router.push(
        BACK_MAP_PATHNAME[history.currentPathname as keyof typeof BACK_MAP_PATHNAME](
          ...Object.values(router.query) as string[],
        ),
      );
      return;
    }

    await router.push('/');
  }, [history.currentPathname, history.previous, router]);

  const reset = useCallback(() => {
    setHistory({
      previous: null,
      current: null,
      currentPathname: null,
    });
  }, [setHistory]);

  return {
    history,
    goBack,
    shouldShowBack,
    reset,
  };
};

export const [
  HistoryContextProvider,
  useHistoryContext,
] = constate(useHistory);
