import { ApolloLink } from '@apollo/client';

import { getHeaders } from '../get-headers';

export const useAuthLink = () => new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...getHeaders(),
      ...headers,
    },
  }));

  return forward(operation);
});
