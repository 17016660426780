
import { Spinner, Stack } from '@lualtek/react-components';
import {
  cloneElement, useCallback, useRef, useState,
} from 'react';

import { usePullToRefreshContext } from '@/context/use-pull-to-refresh-context';

import styles from './pull-to-refresh.module.css';

const REFRESH_THRESHOLD = 50;
const PULL_THRESHOLD = 200;

export const usePullToRefresh = ({ ref }: {
  ref: React.RefObject<HTMLDivElement>;
}) => {
  const { refetchAll } = usePullToRefreshContext();
  const [pullProgress, setPullProgress] = useState(0);
  const [pullDistance, setPullDistance] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [canStartPull, setCanStartPull] = useState(false);

  const onTouchStart = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    setPullProgress(0);
    setPullDistance(event.touches[0].clientY);

    if (event.touches[0].clientY <= PULL_THRESHOLD) {
      setCanStartPull(true);
    }
  }, []);

  const onTouchMove = useCallback(async (event: React.TouchEvent<HTMLDivElement>) => {
    if (ref.current?.scrollTop === 0 && canStartPull) {
      const newCurrentY = event.touches[0].clientY;
      const progress = newCurrentY - pullDistance;
      if (progress < 0) {
        return;
      }

      ref.current?.style.setProperty('overflow', 'hidden');
      setPullProgress(progress > REFRESH_THRESHOLD ? REFRESH_THRESHOLD : progress);
    }
  }, [pullDistance, ref, canStartPull]);

  const onTouchEnd = useCallback(async () => {
    if (pullProgress < REFRESH_THRESHOLD) {
      setPullProgress(0);
      ref.current?.style.setProperty('overflow', 'auto');
      setCanStartPull(false);
      return;
    }

    setPullProgress(REFRESH_THRESHOLD);
    setIsRefreshing(true);
    await refetchAll();
    setIsRefreshing(false);
    setPullProgress(0);
    ref.current?.style.setProperty('overflow', 'auto');
    setCanStartPull(false);
  }, [pullProgress, ref, refetchAll]);

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    pullProgress,
    isRefreshing,
  };
};

export const PullToRefresh: FCChildrenClass<{
  children: React.ReactElement;
}> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    pullProgress,
    isRefreshing,
  } = usePullToRefresh({ ref });

  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      className={styles.PullToRefresh}
      data-refreshing={isRefreshing}
      style={{
        paddingTop: pullProgress,
      }}
    >
      <Stack
        hAlign="center"
        vAlign="end"
        fill={false}
        className={styles.SpinnerContainer}
        style={{
          height: pullProgress || 0,
        }}
      >
        <Spinner className={styles.Spinner} />
      </Stack>
      {cloneElement(children, { ref })}
    </div>
  );
};
