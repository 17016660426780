
      export default {
  "possibleTypes": {
    "Entity": [
      "Asset",
      "Author",
      "Message",
      "MessagesCollection",
      "Release",
      "ReleasesCollection",
      "ScheduledOperation",
      "ScheduledRelease",
      "User"
    ],
    "Node": [
      "Asset",
      "Author",
      "MessagesCollection",
      "ReleasesCollection",
      "ScheduledOperation",
      "ScheduledRelease",
      "User"
    ],
    "ScheduledOperationAffectedDocument": [
      "Asset",
      "Author",
      "MessagesCollection",
      "ReleasesCollection"
    ]
  }
}
    