import { useEffect, useState } from 'react';

/**
 * Custom hook to listen to the scroll event of a specific element.
 *
 * @param {React.RefObject<HTMLElement>} elementRef - The ref of the element to attach the scroll event.
 * @param {Function} callback - The callback function to be called on scroll.
 */
export const useScrollListener = (elementRef: React.RefObject<HTMLElement>, callback: () => void) => {
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return () => null;
    }

    const handleScroll = () => {
      setLastScrollPosition(element.scrollTop);
      const isAtBottom = Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight;

      if (lastScrollPosition > element.scrollTop) {
        return;
      }

      if (isAtBottom) {
        callback();
      }
    };

    element.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on unmount
    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef, callback, lastScrollPosition]);
};
