import { IconNames } from '@lualtek/icons';
import {
  Avatar, BlankButton, ClampText, Datetime, IconChip, IconChipProps, Stack, Text,
} from '@lualtek/react-components';
import {
  forwardRef,
  MouseEventHandler, useCallback, useMemo, useRef,
} from 'react';
import { useFocusEffect, useRovingTabIndex } from 'react-roving-tabindex';

import { useTranslate } from '@/core/i18n';

import styles from './notification.module.css';

export type NotificationProps = {
  kind?: 'message' | 'release' | 'event';
  title: string;
  date?: string | null;
  description?: React.ReactNode;
  isNew?: boolean;
  sentiment?: 'positive' | 'danger' | 'warning';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  appName?: string;
  avatar?: string;
}

const eventIcons: Record<string, {
  icon: IconNames;
  color: IconChipProps['color'];
}> = {
  positive: {
    icon: 'check',
    color: 'gray',
  },
  danger: {
    icon: 'c-remove',
    color: 'red',
  },
  warning: {
    icon: 'c-info',
    color: 'yellow',
  },
};

export const Notification = forwardRef<HTMLDivElement, PropsWithClass<NotificationProps>>(({
  className,
  description,
  kind = 'event',
  sentiment = 'positive',
  title,
  date,
  isNew,
  onClick,
  appName,
  avatar,
  ...otherProps
}, ref) => {
  const itemRef = useRef<HTMLButtonElement>(null);
  const [tabIndex, isFocused, handleKeyDown, handleClick] = useRovingTabIndex(itemRef, false);
  const { t, langCanonical } = useTranslate();

  useFocusEffect(isFocused, itemRef);

  const triggerClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (onClick) {
        handleClick();
        onClick?.(e);
      }
    },
    [handleClick, onClick],
  );

  const releaseChipColor = useMemo(() => (isNew ? 'green' : 'primary'), [isNew]);

  return (
    <Stack
      as={BlankButton}
      ref={itemRef}
      className={styles.Notification}
      vAlign={description ? 'start' : 'center'}
      data-notification-new={isNew}
      direction="row"
      vPadding={24}
      hPadding={24}
      columnGap={16}
      fill={false}
      onClick={triggerClick}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex}
      {...otherProps}
    >
      {(kind === 'event' || kind === 'release') ? (
        <IconChip
          icon={kind === 'release' ? 'block' : eventIcons[sentiment].icon}
          color={kind === 'release' ? releaseChipColor : eventIcons[sentiment].color}
        />
      ) : <Avatar src={avatar ?? '/touch-icon.png'} />}
      <Stack rowGap={8} ref={ref}>
        <Text size={16} weight="bold" lineHeight="small" dimmed={8}>{title}</Text>
        <Text size={14} dimmed={5} lineHeight="small">
          {date ? (
            <Datetime
              date={date}
              locale={langCanonical}
              options={{
                timeStyle: 'medium',
                dateStyle: 'short',
                hour12: false,
              }}
            />
          ) : null}
          {kind === 'event' && appName && (
            <>
              {' '}
              {t('common:glossary.from')}
              {' '}
              <Text as="b">{appName}</Text>
            </>
          )}
        </Text>
        {description && (
          <Text size={16} maxWidth="30ch">
            <ClampText rows={2}>
              {description}
            </ClampText>
          </Text>
        )}
      </Stack>
    </Stack>
  );
});
