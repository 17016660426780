import { DrawerProps } from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { useNotificationsMutations } from '@/api/notifications';

import { EventsDrawer, MessageDrawer, ReleaseDrawer } from '../drawers';
import { DRAWER_KINDS } from './use-notification-render';

export const useNotificationsLogic = () => {
  const { markAllRead } = useNotificationsMutations();

  const router = useRouter();
  const drawerOpened = useMemo(() => match({
    drawer: router.query.drawer,
    notificationContentId: router.query.notificationContentId,
  })
    .with({ drawer: DRAWER_KINDS.EVENTS }, () => DRAWER_KINDS.EVENTS)
    .with({ drawer: DRAWER_KINDS.RELEASE, notificationContentId: P.string }, () => DRAWER_KINDS.RELEASE)
    .with({ drawer: DRAWER_KINDS.MESSAGE, notificationContentId: P.string }, () => DRAWER_KINDS.MESSAGE)
    .otherwise(() => undefined), [router.query.drawer, router.query.notificationContentId]);

  const renderDrawer = useCallback(
    (props: DrawerProps) => match(drawerOpened)
      .with(DRAWER_KINDS.EVENTS, () => <EventsDrawer {...props} />)
      .with(DRAWER_KINDS.RELEASE, () => <ReleaseDrawer {...props} />)
      .with(DRAWER_KINDS.MESSAGE, () => <MessageDrawer {...props} />)
      .otherwise(() => null),
    [drawerOpened],
  );

  const onCloseDrawer = useCallback(async () => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      drawer: _, notificationContentId: __, ...newQuery
    } = router.query;
    await router.replace({
      query: {
        ...newQuery,
      },
    }, undefined, { shallow: true });
  }, [router]);

  const onMarkAllRead = useCallback(() => {
    void markAllRead({ shouldRefetch: true });
  }, [markAllRead]);

  return {
    renderDrawer,
    onCloseDrawer,
    drawerOpened,
    onMarkAllRead,
  };
};
