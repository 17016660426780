import { useMemo } from 'react';

import { useCurrentQuery } from '@/core/navigation/use-current-query';
import { useApplicationsQuery, useApplicationWeatherQuery } from '@/generated/graphql';
import { extractError } from '@/services/apollo/extract-error';

import { HookProps } from '../types';

export const useApplications = ({ userId }: { userId?: number }) => {
  const { appid } = useCurrentQuery();
  const { data, loading, error } = useApplicationsQuery({
    fetchPolicy: 'cache-first',
    variables: {
      userId: userId ?? 0,
    },
    skip: !userId,
  });

  const applicationsFormatted = useMemo(
    () => data?.applications?.map(application => ({
      id: application.id,
      name: application.name,
      devicesCount: application.devicesAggregate?.aggregate?.count ?? 0,
      automationsCount: application.automationsAggregate?.aggregate?.count ?? 0,
      organizationId: application.organizationId!,
    })), [data?.applications],
  );

  const currentApplication = useMemo(
    () => applicationsFormatted?.find(app => app.id === appid),
    [appid, applicationsFormatted],
  );

  return {
    loading,
    applications: applicationsFormatted ?? [],
    currentApplication,
    error,
  };
};

export const useApplicationWeather = ({ applicationId }: { applicationId: number }, props?: HookProps) => {
  const { data, loading, error } = useApplicationWeatherQuery({
    variables: {
      applicationId,
    },
    skip: !applicationId,
    fetchPolicy: 'cache-first',
    onError: error => props?.onError?.(extractError(error)),
  });

  return {
    loading,
    weather: data?.applicationWeather?.weather,
    error,
  };
};
