import { useCallback } from 'react';
import { match } from 'ts-pattern';

type IconWeather =
| 'cloudy' // done
| 'fog' // done
| 'night' // done
| 'showers' // done
| 'snow' // done
| 'sprinkle' // done
| 'sprinkleNight' // done
| 'sunny' // done
| 'thunderstorms' // done
| 'fewClouds' // done
| 'fewCloudsNight' // done
| 'stormyShowers'
| 'sleet'
| 'hail'
| 'windySnow';

type TimeKind = 'dawn' | 'afternoon' | 'dusk' | 'night';

export const useWeatherHelpers = () => {
  const timeKind = useCallback(({
    time,
    sunrise,
    sunset,
  }: { time?: Date; sunset?: Date; sunrise?: Date }): TimeKind => {
    if (!time || !sunset || !sunrise) {
      return 'dawn';
    }

    const currentHour = time.getHours();
    const sunriseHour = sunrise.getHours();
    const sunsetHour = sunset.getHours();

    if (currentHour === sunriseHour) {
      return 'dawn';
    }

    if (currentHour === sunsetHour) {
      return 'dusk';
    }

    if (currentHour > sunriseHour && currentHour < sunsetHour) {
      return 'afternoon';
    }

    return 'night';
  }, []);

  // NOTE: using images name from https://openweathermap.org/weather-conditions
  const iconWeatherForId = useCallback((imageId?: string): IconWeather => match(imageId)
    .with('01d', () => 'sunny' as const)
    .with('01n', () => 'night' as const)
    .with('02d', () => 'fewClouds' as const)
    .with('02n', () => 'fewCloudsNight' as const)
    .with('03d', '03n', '04d', '04n', () => 'cloudy' as const)
    .with('11d', '11n', () => 'thunderstorms' as const)
    .with('09d', '09n', () => 'showers' as const)
    .with('10d', () => 'sprinkle' as const)
    .with('10n', () => 'sprinkleNight' as const)
    .with('13d', '13n', () => 'snow' as const)
    .with('50d', '50n', () => 'fog' as const)

    .otherwise(() => 'sunny' as const), []);

  return {
    iconWeatherForId,
    timeKind,
  };
};
