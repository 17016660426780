
const ids = {
  components: {
    deviceCardSmall: 'device-card-small',
    lastScenesRow: 'last-scenes-row',
  },
  dashboard: {
    favouriteDevices: 'dashboard-favourite-devices',
    favouriteAnalysis: 'dashboard-favourite-analysis',
    weather: 'dashboard-weather',
    weatherForecast: 'dashboard-weather-forecast',
    weatherDate: 'dashboard-weather-date',
    lastScenes: 'dashboard-last-scenes',
  },
  shell: {
    mainMenu: 'shell-main-menu',
    mainMenuButton: 'shell-main-menu-button',
  },
};

export const testIds = ids;
