import { RichText, RichTextProps } from '@graphcms/rich-text-react-renderer';
import {
  Polymorphic, Text, Title, TitleProps,
} from '@lualtek/react-components';
import { forwardRef } from 'react';

// import { GradientText } from '@/components/gradient-text';

export type CmsTitleProps = TitleProps & {
  content: RichTextProps['content'];
}

type PolymorphiCmsTitle = Polymorphic.ForwardRefComponent<
Polymorphic.IntrinsicElement<typeof Title>,
Polymorphic.OwnProps<typeof Title> & CmsTitleProps
>;

export const CmsTitle = forwardRef(({
  content,
  ...otherProps
}, ref) => (
  <RichText
    content={content}
    renderers={{
      // TODO: prima era gradient text
      bold: ({ children }) => <Text weight="bold">{children}</Text>,
      p: ({ children }) => <Title ref={ref} {...otherProps}>{children}</Title>,
    }}
  />
)) as PolymorphiCmsTitle;
