import { IconNames } from '@lualtek/icons';
import {
  Glow,
  Icon, Stack,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { m } from 'framer-motion';
import Link from 'next/link';

import { testIds } from '@/core/helpers/test-ids';
import { useNavigation } from '@/core/navigation/use-navigation';

import styles from './navigation.module.css';

type NavigationProps = PropsWithClass & Record<string, unknown>

type NavLinkProps = {
  href: string;
  label: string;
  icon: IconNames;
  active?: boolean;
}

const NavLink = ({
  href,
  label,
  icon,
  active,
}: NavLinkProps) => (
  <Glow
    borderOffset={0}
    fitContent
    borderWidth={1}
    innerRadius={16}
    glowPower={0}
  >
    <Stack
      as={Link}
      href={href}
      direction="row"
      vAlign="center"
      hAlign="center"
      vPadding={16}
      hPadding={16}
      fill={false}
      className={styles.Link}
      aria-current={active ? 'page' : undefined}
      data-testid={testIds.shell.mainMenuButton}
    >
      <Icon source={icon} dimension={24} aria-label={label} />

      {active && (
        <m.span
          className={styles.Highlight}
          animate={{ opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 800,
            damping: 50,
          }}
          layoutId="nav-highlight"
        />
      )}
    </Stack>
  </Glow>
);

export const Navigation = ({
  className,
  ...otherProps
}: NavigationProps) => {
  const { mainMenu } = useNavigation();
  return (
    <Stack
      as="nav"
      rowGap={8}
      className={clsx(styles.Navigation, className)}
      data-testid={testIds.shell.mainMenu}
      hAlign="center"
      fill={false}
      {...otherProps}
    >

      {mainMenu.map(item => (
        <NavLink
          key={item.label}
          href={item.href}
          label={item.label}
          icon={item.icon}
          active={item.active}
        />
      ))}
    </Stack>
  );
};
