import React from 'react';

type StatusProps = {
  children?: React.ReactNode;
  loading?: boolean;
  LoadingComponent?: React.ReactNode;
  error?: boolean;
  ErrorComponent?: React.ReactNode;
  empty?: boolean;
  EmptyComponent?: React.ReactNode;
}

export const Status = ({
  children,
  loading,
  LoadingComponent,
  error,
  ErrorComponent,
  empty,
  EmptyComponent,
}: StatusProps) => {
  if (error) {
    return <>{ErrorComponent ?? 'Error'}</>;
  }

  if (loading) {
    return <>{LoadingComponent}</>;
  }

  if (empty) {
    return <>{EmptyComponent}</>;
  }

  return (
    <>{children}</>
  );
};
