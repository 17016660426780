import { ApolloProvider as ApolloClientProvider } from '@apollo/client';

import { useApollo } from './apollo-client';

export const ApolloProvider: FCChildren<{ initialApolloState: Record<string, unknown> }> = (pageProps) => {
  const { initialApolloState, children } = pageProps;
  const apolloClient = useApollo(initialApolloState);

  return (
    <ApolloClientProvider client={apolloClient}>
      {children}
    </ApolloClientProvider>
  );
};

