import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { getHeaders } from '../get-headers';

const wsUri = process.env.NEXT_PUBLIC_APOLLO_WS_URI;

export const wsLink = typeof window !== 'undefined' && new GraphQLWsLink(
  createClient({
    url: wsUri,
    connectionParams: () => ({ headers: getHeaders() }),
    connectionAckWaitTimeout: 10000,
  }),
);
