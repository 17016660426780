import constate from 'constate';
import {
  ReactNode, useCallback, useEffect, useRef,
} from 'react';

const useMobile = ({ initialValue }: { initialValue?: boolean }) => {
  const isMobileRef = useRef(initialValue);

  useEffect(() => {
    document.documentElement.dataset.mobile = String(isMobileRef.current);
  }, []);

  const renderConditionally = useCallback(
    <T extends ReactNode | unknown[]>(mobile: T, desktop: T) => (isMobileRef.current ? mobile : desktop),
    [],
  );

  return {
    isMobile: isMobileRef.current,
    renderConditionally,
  };
};

export const [
  MobileContextProvider,
  useMobileContext,
] = constate(useMobile);
