import {
  Stack, Textfield,
} from '@lualtek/react-components';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useDebounce } from 'rooks';

import { AppSwitcher } from '@/components/common/desktop/app-switcher';

import { AppListItem } from './app-list-item';
import styles from './apps-list.module.css';

type ApplicationType = {
  id: number;
  name: string;
  devicesCount?: number;
  automationsCount?: number;
}

type AppListProps = {
  onAppSelect: (id: number) => void;
  applications: ApplicationType[];
  selectedApplication?: ApplicationType;
}

export const AppsList: FCClass<AppListProps> = ({
  className,
  applications,
  onAppSelect,
  selectedApplication,
  ...otherProps
}) => {
  const [value, setValue] = useState('');

  // TODO: something is wrong here
  // eslint-disable-next-line
  const setValueDebounced = useDebounce(setValue, 320);

  const filteredApplication = useMemo(
    () => applications.filter(
      app => app.name.toLowerCase().includes(value.toLowerCase()) && app.id !== selectedApplication?.id,
    ),
    [applications, value, selectedApplication],
  );

  const isOnly = useMemo(() => applications.length === 1, [applications]);

  return (
    <Stack
      className={clsx(styles.AppsList, className)}
      vPadding={16}
      hPadding={16}
      rowGap={16}
      {...otherProps}
    >
      {selectedApplication && <AppSwitcher showWeather={false} />}
      {applications.length > 5 && (
        <Textfield
          label="Filter applications"
          icon="zoom"
          iconPosition="start"
          autoComplete="off"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          onChange={event => setValueDebounced(event.target.value) as unknown}
          className={styles.Search}
        />
      )}
      <Stack className={styles.List} rowGap={4}>
        {filteredApplication.map(app => (
          <AppListItem
            key={app.id}
            id={app.id}
            name={app.name}
            selectedApplication={selectedApplication?.id === app.id}
            devices={app.devicesCount}
            automations={app.automationsCount}
            onAppSelect={onAppSelect}
            isOnly={isOnly}
          />
        ))}
      </Stack>
    </Stack>
  );
};
