
import {
  Chip, Tabs, TabsProps,
} from '@lualtek/react-components';
import {
  CSSProperties, useMemo,
} from 'react';

import { useNotificationsNotReadByType } from '@/api/notifications';
import { useTranslate } from '@/core/i18n';

import { EventsPanel } from './panels/events-panel';
import { MessagesPanel } from './panels/messages-panel';
import { ReleasesPanel } from './panels/releases-panel';

type NotificationsTabProps = TabsProps & {
  maxHeight?: string;
  minHeight?: string;
}

export const NotificationsTab: FCClass<NotificationsTabProps> = ({
  className,
  maxHeight,
  minHeight = '300px',
  style,
  ...otherProps
}) => {
  const { t } = useTranslate();
  const {
    eventsNotRead,
    messagesNotRead,
    releasesNotRead,
    loading,
  } = useNotificationsNotReadByType();

  const dynamicStyle: CSSProperties = useMemo(() => ({
    '--max-height': maxHeight,
    '--min-height': minHeight,
  }), [maxHeight, minHeight]);

  return (
    <Tabs
      defaultValue="events"
      className={className}
      style={{ ...dynamicStyle, ...style }}
      {...otherProps}
    >
      <Tabs.Panel
        value="events"
        label={t('common:glossary.events')}
        decorator={(!loading && eventsNotRead > 0) ? <Chip dimension="small">{eventsNotRead}</Chip> : undefined}
      >
        <EventsPanel />
      </Tabs.Panel>
      <Tabs.Panel
        value="messages"
        label={t('common:glossary.messages')}
        decorator={(!loading && messagesNotRead > 0) ? <Chip dimension="small">{messagesNotRead}</Chip> : undefined}
      >
        <MessagesPanel />
      </Tabs.Panel>
      <Tabs.Panel
        value="releases"
        label={t('common:glossary.releases')}
        decorator={(!loading && releasesNotRead > 0) ? <Chip dimension="small">{releasesNotRead}</Chip> : undefined}
      >
        <ReleasesPanel />
      </Tabs.Panel>
    </Tabs>
  );
};
