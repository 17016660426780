import {
  Button, IconButton, InfoState, Panel, Separator, Skeleton, Stack,
} from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useMemo } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';

import { useNotificationsEvents } from '@/api/notifications';
import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';

import { Notification } from '../../notification/notification';
import { DRAWER_KINDS, useNotificationRender } from '../../notification/use-notification-render';
import { Status } from '../../status';
import styles from '../notifications-tab.module.css';

export const EventsPanel = () => {
  const { t } = useTranslate();
  const router = useRouter();
  const { isMobile } = useMobileContext();
  const { lastEvents, loading } = useNotificationsEvents();
  const { generateEventProps } = useNotificationRender();

  const onOpenDrawer = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { drawer: _, notificationContentId: __, ...newQuery } = router.query;
    void router.replace({
      query: {
        ...newQuery,
        drawer: DRAWER_KINDS.EVENTS,
      },
    }, undefined, { shallow: true });
  }, [router]);

  const hasNoData = useMemo(() => lastEvents.length === 0, [lastEvents.length]);

  return (
    <Stack className={styles.TabContent} vAlign="start" fill={false}>
      <Status
        loading={loading}
        LoadingComponent={(
          <Stack hPadding={8} vPadding={8}>
            <Skeleton count={5} height={64} gap={4} />
          </Stack>
        )}
        EmptyComponent={(
          <Stack className={styles.EmptyContainer} vPadding={32} vAlign="center" fill={false}>
            <InfoState
              icon="check"
              iconColor="green"
              title={t('common:notifications.emptyState.title')}
            />
          </Stack>
        )}
        empty={hasNoData}
      >
        <RovingTabIndexProvider options={{ direction: 'vertical' }}>
          {lastEvents.map((notification, index) => (
            <Fragment key={notification.id}>
              <Notification
                {...generateEventProps(notification)}
              />
              {index < lastEvents.length - 1 ? <Separator hPadding={24} /> : null}
            </Fragment>
          ))}
        </RovingTabIndexProvider>
      </Status>

      {!hasNoData && (
        <Panel
          vibrant={isMobile}
          bordered
          borderSide="top"
          backgroundColor={0}
          className={styles.Footer}
        >
          <Stack
            hAlign="end"
            vAlign="center"
            hPadding={16}
          >
            {isMobile ? (
              <IconButton
                kind="secondary"
                icon="clock"
                onClick={onOpenDrawer}
              />
            ) : (
              <Button
                onClick={onOpenDrawer}
                kind="secondary"
                dimension="small"
                icon="clock"
              >
                {t('common:cta.viewHistory')}
              </Button>
            )}
          </Stack>
        </Panel>
      )}
    </Stack>
  );
};
