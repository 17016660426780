import { Modal } from '@lualtek/react-components';
import { FC } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';

import { AppsList } from '@/components/common/shared/apps-list';
import { useApplicationModalContext } from '@/context/use-application-modal-context';
import { useMobileContext } from '@/context/use-mobile-context';
import { useTranslate } from '@/core/i18n';

type Props = {
  applications: Array<{
    id: number;
    name: string;
    devicesCount: number;
    automationsCount: number;
  }>;
  currentApp?: {
    id: number;
    name: string;
    devicesCount: number;
    automationsCount: number;
  };
}

export const ApplicationModal: FC<Props> = ({ applications, currentApp }) => {
  const { t } = useTranslate();
  const { isMobile } = useMobileContext();
  const {
    isApplicationModalVisible,
    closeApplicationModal,
    goToApplication,
  } = useApplicationModalContext();

  return (
    <Modal isOpen={isApplicationModalVisible} autoFocus={!isMobile} onClose={closeApplicationModal}>
      <Modal.Content title={t('common:glossary.changeWorkingApplication')}>
        <RovingTabIndexProvider options={{ direction: 'vertical' }}>
          <AppsList
            applications={applications}
            selectedApplication={currentApp}
            onAppSelect={goToApplication}
          />
        </RovingTabIndexProvider>
      </Modal.Content>
    </Modal>
  );
};
