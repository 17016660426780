import { Stack } from '@lualtek/react-components';

import { Navigation } from '@/components/common/desktop/navigation';
import { Notifications } from '@/components/common/desktop/notifications';
import { UserWidget } from '@/components/common/shared/user-widget';

import styles from './sidebar.module.css';

type SidebarProps = Record<string, unknown>

export const Sidebar: FCChildren<SidebarProps> = ({
  children,
  ...otherProps
}) => (
  <div className={styles.Sidebar} {...otherProps}>
    <Stack vAlign="space-between" fill={false} className={styles.Content}>
      <Navigation />

      <Stack rowGap={24}>
        {children}
        <Stack
          direction="column"
          hAlign="center"
          rowGap={16}
          columnGap={16}
          fill={false}
        >
          <UserWidget />
          <Notifications />
        </Stack>
      </Stack>
    </Stack>
  </div>
);
