import { ToastAction } from '@lualtek/react-components';
import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useLocalstorageState, useTimeoutWhen } from 'rooks';

import { useFeedbackContext } from '@/context/use-feedback-context';
import { useTranslate } from '@/core/i18n';

import { useUser } from '../../api/user';

export const notificationService = OneSignal;
export const useInitNotification = () => {
  const { user } = useUser();
  const { t } = useTranslate();
  const [isNotificationReady, setIsNotificationReady] = useState(false);
  const { addFeedback, resetFeedbacks } = useFeedbackContext();
  const [latestToastShown, setLatestToastShown] = useLocalstorageState<string>(
    'enable-notifications-toast-lualtek-shown',
    '',
  );

  // Check if toast was shown in the last 7 days
  const shouldShowToast = useCallback(() => {
    if (!latestToastShown || latestToastShown === 'undefined') {
      return true;
    }

    const lastToastShown = new Date(latestToastShown);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    return lastToastShown < sevenDaysAgo;
  }, [latestToastShown]);

  const onConfirmAllow = useCallback(async () => {
    resetFeedbacks();
    await OneSignal.showNativePrompt();
  }, [resetFeedbacks]);

  const addUser = useCallback(async () => {
    if (!isNotificationReady || !user) {
      return;
    }

    await OneSignal.setSubscription(true);
    await OneSignal.setExternalUserId(String(user.id));
    await OneSignal.sendTags({
      organizations: user.organizations?.map(({ id }) => id),
    });
  }, [isNotificationReady, user]);

  const afterInit = useCallback(async () => {
    // Add user again when the user accepts the native prompt
    OneSignal.once('notificationPermissionChange', (permission: { to: string }) => {
      if (permission.to === 'granted') {
        void addUser();
      }
    });

    const permissionStatus = await OneSignal.getNotificationPermission();

    // If permission status checked and is "default"
    // otherwise it will be "granted" or "denied" and we don't need to show the message or take actions
    if (user && permissionStatus === 'default' && shouldShowToast()) {
      addFeedback({
        title: t('common:feedback.allowNotifications.title'),
        children: t('common:feedback.allowNotifications.text'),
        actions: (
          <ToastAction
            altText={t('common:cta.enable')}
            onClick={onConfirmAllow}
          >
            {t('common:cta.enable')}
          </ToastAction>
        ),
        duration: 20000,
      });
      setLatestToastShown(new Date().toISOString());
    }

    if (user && permissionStatus === 'granted') {
      await addUser();
    }
  }, [addFeedback, addUser, onConfirmAllow, setLatestToastShown, shouldShowToast, t, user]);

  const initOneSignal = useCallback(async () => {
    // Do nothing if already initialized
    if (isNotificationReady) {
      return;
    }

    await OneSignal.init({
      appId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
      safari_web_id: process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_ID,
      autoResubscribe: true,
      allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
    });
  }, [isNotificationReady]);

  useEffect(() => {
    void initOneSignal().then(() => setIsNotificationReady(true));
  }, [initOneSignal]);

  useEffect(() => {
    if (isNotificationReady) {
      void afterInit();
    }
  }, [isNotificationReady, afterInit]);
};

export const usePushNotifications = () => {
  const { user } = useUser();
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [isPermissionGranted, setPermissionsGranted] = useState(false);
  const [currentPermission, setCurrentPermission] = useState<NotificationPermission | null>(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const checkPermissions = useCallback(async () => {
    const result = await OneSignal.getNotificationPermission();
    setPermissionsGranted(result === 'granted');
    setCurrentPermission(result);
  }, []);

  const checkSubscription = useCallback(async () => {
    const isPushEnabled = await OneSignal.isPushNotificationsEnabled();
    setIsSubscribed(isPushEnabled);
  }, []);

  const addUser = useCallback(async () => {
    await OneSignal.setSubscription(true);
    await OneSignal.setExternalUserId(String(user?.id));
    await OneSignal.sendTags({
      organizations: user?.organizations?.map(({ id }) => id),
    });
  }, [user]);

  const showNativePrompt = useCallback(async () => {
    await OneSignal.showNativePrompt();
  }, []);

  const recheck = useCallback(async () => {
    await checkPermissions();
    await checkSubscription();
  }, [checkPermissions, checkSubscription]);

  const onChangePermissions = useCallback(async (permission: { to: string }) => {
    // If changed to granted, resubscribe user directly
    if (permission.to === 'granted') {
      await addUser();
    }
  }, [addUser]);

  useTimeoutWhen(() => setLoadingSubscription(false), 5000, loadingSubscription);

  useEffect(() => {
    OneSignal.on('notificationPermissionChange', onChangePermissions);
    return () => {
      OneSignal.off('notificationPermissionChange', onChangePermissions);
    };
  }, [onChangePermissions]);

  useEffect(() => {
    setLoadingSubscription(true);
    void recheck().then(() => setLoadingSubscription(false)).catch(() => setLoadingSubscription(false));
  }, [recheck]);

  /**
   * Seems like OneSingal.getNotificationPermission won't reject correctly in some occasions
   * so we need to set a timeout to avoid loading forever
   */
  // useTimeoutWhen(() => setLoadingSubscription(false), 5000, loadingSubscription);

  return {
    isPermissionGranted,
    unsubscribe: async () => OneSignal.setSubscription(false),
    subscribe: addUser,
    showNativePrompt,
    isSubscribed,
    loadingSubscription,
    currentPermission,
  };
};
