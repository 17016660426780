
import { getJWT } from '@/core/auth/jwt';

export const getHeaders = () => {
  const headers: HeadersInit = {
    'lualtek-app-host': window.location.host,
    'x-hasura-role': 'anonymous',
  };

  const token = getJWT();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
    headers['x-hasura-role'] = 'user';
  }

  return headers;
};
