import {
  IconButton, Modal, Stack,
} from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useNotificationsNotReadCount } from '@/api/notifications';
import { NavLink } from '@/components/common/mobile/navlink';
import { NotificationsTab } from '@/components/common/shared/notifications-tab';
import { useTranslate } from '@/core/i18n';

import { useNotificationsLogic } from '../../shared/notification/use-notifications-logic';
import styles from './notifications.module.css';

type NotificationsProps = Record<string, unknown>

export const Notifications: FCClass<NotificationsProps> = ({
  className,
  ...otherProps
}) => {
  const router = useRouter();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { notReadCount } = useNotificationsNotReadCount();
  const { t } = useTranslate();
  const {
    drawerOpened,
    onCloseDrawer,
    renderDrawer,
    onMarkAllRead,
  } = useNotificationsLogic();

  useEffect(() => {
    const handleRouteChange = async (_: string, props: { shallow?: boolean }) => {
      // Avoid closing the modal when we open the drawer
      if (!props.shallow) {
        setIsNotificationsOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [onCloseDrawer, router.events]);

  return (
    <>
      <NavLink
        onClick={() => setIsNotificationsOpen(true)}
        icon="bell"
        showBadge={notReadCount > 0}
        {...otherProps}
      />
      <Modal isOpen={isNotificationsOpen} onClose={() => setIsNotificationsOpen(false)}>
        <Modal.Content safePadding={false} scrollInside title={t('common:glossary.notifications')}>
          <Stack direction="row" inline columnGap={4} className={styles.Settings}>
            {notReadCount > 0 ? (
              <IconButton
                onClick={onMarkAllRead}
                sentiment="positive"
                icon="check-double"
              />
            ) : null}
            <IconButton
              as="a"
              onClick={async () => router.push('/account')}
              icon="settings-gear"
              kind="secondary"
            />
          </Stack>
          <NotificationsTab
            maxHeight="80dvh"
            minHeight="80dvh"
            listGap={2}
          />
        </Modal.Content>
      </Modal>
      {renderDrawer({ isOpen: Boolean(drawerOpened), onClose: onCloseDrawer, safePadding: false })}
    </>
  );
};
