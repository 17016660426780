import {
  InfoState,
  Separator, Skeleton, Stack,
} from '@lualtek/react-components';
import { Fragment } from 'react';
import { RovingTabIndexProvider } from 'react-roving-tabindex';

import { useNotificationsReleases } from '@/api/notifications';
import { useTranslate } from '@/core/i18n';

import { Notification } from '../../notification/notification';
import { useNotificationRender } from '../../notification/use-notification-render';
import { Status } from '../../status';
import styles from '../notifications-tab.module.css';

export const ReleasesPanel = () => {
  const { t } = useTranslate();
  const { lastReleases, loading } = useNotificationsReleases();
  const { generateOthersProps } = useNotificationRender();

  return (
    <Stack className={styles.TabContent} vAlign="start" fill={false}>
      <Status
        loading={loading}
        empty={lastReleases.length === 0}
        LoadingComponent={(
          <Stack hPadding={8} vPadding={8}>
            <Skeleton count={5} height={64} gap={4} />
          </Stack>
        )}
        EmptyComponent={(
          <Stack className={styles.EmptyContainer} vPadding={32} vAlign="center" fill={false}>
            <InfoState
              icon="check"
              iconColor="green"
              title={t('common:notifications.emptyState.title')}
            />
          </Stack>
        )}
      >
        <RovingTabIndexProvider options={{ direction: 'vertical' }}>
          {lastReleases.map((notification, index) => (
            <Fragment key={notification.id}>
              <Notification
                {...generateOthersProps(notification)}
                title={t('common:glossary.newRelease', { version: notification.content.hygraph?.release.version })}
                date={notification.content.hygraph?.publishedAt}
              />
              {index < lastReleases.length - 1 ? <Separator hPadding={24} /> : null}
            </Fragment>
          ))}
        </RovingTabIndexProvider>
      </Status>
    </Stack>
  );
};
