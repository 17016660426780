// const dynamicUseMouse = dynamic(async () => import('ahooks').then(mod => mod.useMouse), { ssr: false });
import { Panel, Stack, useResponsiveContext } from '@lualtek/react-components';
import { TokensTypes } from '@lualtek/tokens/platforms/web';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import React, {
  CSSProperties, useMemo, useRef,
} from 'react';
import { useMouse } from 'rooks';

import { ThemeSwitcherProps } from '@/components/common/desktop/theme-switcher/theme-switcher';
import { BaseLayout } from '@/components/layouts/shared/base-layout';
import { useMobileContext } from '@/context/use-mobile-context';

import { GateLayoutType } from '../../types';
import styles from './gate-layout.module.css';

const DynThemeSwitcher = dynamic<ThemeSwitcherProps>(
  async () => import('@/components/common/desktop/theme-switcher').then(mod => mod.ThemeSwitcher),
  { ssr: false },
);

export type GateLayoutProps = {
  contentPadding?: TokensTypes['space'];
}

export const GateLayout: GateLayoutType = ({
  className,
  children,
  contentPadding = 136,
  ...otherProps
}) => {
  const ref = useRef<HTMLElement>(null);
  const { clientX, clientY } = useMouse();
  const clamp = useMemo(() => (num: number, min: number, max: number) => Math.min(Math.max(num, min), max), []);
  const { isMobile } = useMobileContext();
  const { matches } = useResponsiveContext();

  const dynamicStyle: CSSProperties = useMemo(() => ({
    '--x': clientX ? `${clamp(clientX, 0, 400)}px` : null,
    '--y': clientY ? `${clientY}px` : null,
  }), [clientX, clientY, clamp]);

  return (
    <BaseLayout>
      <main
        ref={ref}
        style={dynamicStyle}
        className={clsx(styles.GateLayout, className)}
        {...otherProps}
      >
        <Panel
          vibrant={!isMobile}
          vibrancyColor="soft"
          className={styles.Panel}
          bordered={!isMobile}
          borderSide="right"
        >
          <Stack
            vAlign="center"
            fill={false}
            className={styles.Frame}
            vPadding={32}
            hPadding={matches.medium ? contentPadding : 24}
            rowGap={24}
          >
            <Stack>
              {children}
            </Stack>

            <Stack hAlign="center">
              <DynThemeSwitcher side="top" />
            </Stack>
          </Stack>
        </Panel>
      </main>
    </BaseLayout>
  );
};
