import { getNotificationsGlossaryLang } from '@lualtek/dynamic-glossary';
import { AvailableLanguages } from '@lualtek/dynamic-glossary/types';
import Link from 'next/link';
import I18nProvider from 'next-translate/I18nProvider';
import Trans from 'next-translate/Trans';
import useTranslationNextTranslate from 'next-translate/useTranslation';
import { useCallback, useEffect } from 'react';
import { useLocalstorageState } from 'rooks';

import { NotificationKindsEnum } from '@/generated/graphql';
import { STORAGE_KEY_LOCAL_LANG } from '@/services/local-storage/keys';

import i18nConfig from '../../../i18n.json';

const BCP47 = {
  en: 'en-US',
  it: 'it-IT',
};

const useTranslate = () => {
  const { t, lang } = useTranslationNextTranslate();
  const [localLang, setLocalLang] = useLocalstorageState<'it' | 'en' | 'undefined'>(STORAGE_KEY_LOCAL_LANG);
  const dynamicGlossary = useCallback(
    (kind: NotificationKindsEnum) => getNotificationsGlossaryLang({
      lang: lang as AvailableLanguages,
      version: 1,
    })(kind as 'EVENT_SCENE_WARNING' | 'EVENT_SCENE_SUCCESS' | 'EVENT_SCENE_FAILED' | 'EVENT_DEVICE_MISSING'),
    [lang],
  );

  useEffect(() => setLocalLang(lang as 'it' | 'en'), [lang, setLocalLang]);

  return {
    t,
    // Used when you have template string to avoid "any" eslint error
    tString: (key: string) => t(key),
    lang,
    localLang,
    dynamicGlossary,
    // BCP 47 language tag
    langCanonical: BCP47[lang as keyof typeof BCP47] || 'en-US',
  };
};

const { locales } = i18nConfig;

const useI18nHelpers = () => {
  const { tString } = useTranslate();
  const tGetCardinalDirection = useCallback((key: string) => tString(`common:glossary.cardinalDirections.${key}`), [tString]);
  const tDevicePayloadKey = useCallback((key: string) => tString(`common:device.payload.${key}.label`), [tString]);
  const tDevicePayloadValuesByValue = useCallback((key: string) => tString(`common:device.payloadValues.${key}`), [tString]);
  const tDeviceActionName = useCallback((key: string) => tString(`common:device.actionNames.${key}`), [tString]);
  const tDeviceCategory = useCallback((key: string) => tString(`common:device.categories.${key}`), [tString]);
  const tPasswordConstraint = useCallback((key: string) => tString(`get:form.password.constraints.${key}`), [tString]);
  const tTimeframe = useCallback((key: string) => tString(`common:glossary.${key}`), [tString]);
  const tTimeframeCompare = useCallback((key: string) => tString(`common:timeframe.compare.${key}`), [tString]);
  const tError = useCallback((key: string) => tString(`common:errors.${key}`), [tString]);
  const tDeviceFilter = useCallback((key: string) => tString(`area:filters.${key}`), [tString]);
  const tAutomationsFilter = useCallback((key: string) => tString(`automations:filters.${key}`), [tString]);
  const tAnalysisTitle = useCallback((key: string) => tString(`analyses:analysis.${key}.title`), [tString]);
  const tAnalysisDescription = useCallback((key: string) => tString(`analyses:analysis.${key}.description`), [tString]);
  const tAnalysisGroup = useCallback((key: string) => tString(`analyses:group.card.${key}`), [tString]);
  const tAnalysisGroupSelection = useCallback((key: string) => tString(`analyses:group.selection.${key}`), [tString]);
  const tDeviceSignal = useCallback((key: string) => tString(`common:device.signal.${key}`), [tString]);
  const tAreaScope = useCallback((key: string) => tString(`common:area.scopes.${key}`), [tString]);
  const tAreaDataLabel = useCallback((key: string) => tString(`common:area.labels.${key}`), [tString]);
  const tAreaDataValue = useCallback((key: string) => tString(`common:area.values.${key}`), [tString]);
  const tAreaSubstrateType = useCallback((key: string) => tString(`common:area.substrateTypes.${key}`), [tString]);
  const capFirstLetter = useCallback((str: string) => str.charAt(0).toUpperCase() + str.slice(1), []);
  const uncapFirstLetter = useCallback((str: string) => str.charAt(0).toLowerCase() + str.slice(1), []);

  return {
    tGetCardinalDirection,
    tDevicePayloadKey,
    tDevicePayloadValuesByValue,
    tDeviceActionName,
    tPasswordConstraint,
    tDeviceCategory,
    tTimeframe,
    tDeviceFilter,
    tAutomationsFilter,
    tError,
    tAnalysisTitle,
    tAnalysisDescription,
    tAnalysisGroup,
    tDeviceSignal,
    tAreaScope,
    tAreaDataLabel,
    tAreaDataValue,
    tAreaSubstrateType,
    tTimeframeCompare,
    capFirstLetter,
    uncapFirstLetter,
    tAnalysisGroupSelection,
  };
};

/* Optionally, export class methods as named exports */
export {
  I18nProvider,
  Link,
  locales,
  Trans,
  useI18nHelpers,
  useTranslate,
};
