import {
  Avatar, BlankButton,
  Menu, Popover, Stack, Text,
} from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { useUser } from '@/api/user';
import { useMobileContext } from '@/context/use-mobile-context';
import { Link, useTranslate } from '@/core/i18n';

export const UserWidget = () => {
  const { user } = useUser();
  const { t } = useTranslate();
  const { isMobile } = useMobileContext();

  const router = useRouter();

  const onGoToAccount = useCallback(async () => {
    await router.push('/account');
  }, [router]);

  return (
    <Popover>
      <Popover.Trigger>
        <BlankButton>
          <Avatar src={user?.avatarUrl} />
        </BlankButton>
      </Popover.Trigger>
      <Popover.Content style={{ zIndex: 1 }} side={isMobile ? 'top' : 'right'} align={isMobile ? 'center' : 'start'}>
        <Menu>
          <Stack as="li" hPadding={24} vPadding={16} fill={false} hAlign="start" rowGap={4}>
            <Text as="span" dimmed={7} size={16} weight="bold">{user?.email}</Text>
          </Stack>
          <Menu.Separator />
          <Menu.Item
            as="a"
            // we don't use Link here because there is a strange bug on Android
            onClick={onGoToAccount}
            value="edit-account"
            icon="settings-gear"
          >
            {t('common:glossary.editAccount')}

          </Menu.Item>
          <Menu.Item as={Link} href="/logout" value="logout" icon="logout" sentiment="danger">
            {t('common:cta.logout')}
          </Menu.Item>
        </Menu>
      </Popover.Content>
    </Popover>
  );
};
