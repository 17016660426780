import { Stack } from '@lualtek/react-components';
import { match, P } from 'ts-pattern';

import { useBreadcrumbs } from '@/components/common/shared/breadcrumbs/use-breadcrumbs';
import { useCrumbDetailContext } from '@/context/use-crumb-detail-context';
import { useTranslate } from '@/core/i18n';

import styles from './breadcrumbs.module.css';
import { Crumb } from './crumb';

export const Breadcrumbs: FCChildrenClass = () => {
  const { breads } = useBreadcrumbs();
  const { t } = useTranslate();
  const { crumbDetail } = useCrumbDetailContext();

  return (
    <Stack
      className={styles.Breadcrumbs}
      inline
      direction="row"
      fill={false}
      columnGap={8}
      vAlign="center"
      hAlign="start"
    >
      {breads.map((crumb, idx) => {
        const isLastItem = idx === breads.length - 1;
        const isFatherLastItem = idx === breads.length - 2;
        const crumbToShow = match({ isLastItem, isFatherLastItem, crumbDetail })
          .with(
            { isFatherLastItem: true, crumbDetail: { father: P.nonNullable } },
            ({ crumbDetail }) => crumbDetail.father,
          )
          .with({ isLastItem: true, crumbDetail: P.nonNullable }, ({ crumbDetail }) => crumbDetail)
          .otherwise(() => null);
        const crumbI18n = crumb.i18nKey ? t(crumb.i18nKey) : '';
        return (
          <Crumb
            key={crumb.href}
            href={crumb.href}
            last={isLastItem}
            title={crumbToShow ? crumbToShow.title : crumbI18n}
            subtitle={crumbToShow?.subtitle}
          />
        );
      })}
    </Stack>
  );
};
